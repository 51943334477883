<template>
  <div class="bg-fff" style="height: 100%;">
    <nav-header page-name="Index" title="绑定手机号" fixed/>
    <div class="bang-ding">
      <div>绑定手机号</div>
      <small>
        手机号仅用于登录和保护账号
      </small>
    </div>
    <van-form @submit="onSubmit">
      <van-cell-group inset>
        <van-field
            required
            v-model="form.mobile"
            label="手机号"
            placeholder="请输入手机号"
        />
        <van-field
            required
            v-model="form.code"
            label="验证码"
            placeholder="请输入验证码"
        >
          <template #right-icon>
            <a href="javascript:" @click="getCode(form.mobile)">{{ getCodeTitle }}</a>
          </template>
        </van-field>
      </van-cell-group>
      <div class="form-btn">
        <van-button round block type="primary" native-type="submit">
          完成
        </van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
export default {
  name: 'addMobile',
}
</script>
<script setup>
import NavHeader from "../../components/top/NavHeader";
import {ref, reactive} from 'vue'
import {useRouter, useRoute} from 'vue-router'
import {yanZhengMa} from "../open/yanZhengMaSetup";
import http from "../../api/http";
import {updateUserInfo, getReturnUrl, setUserInfo} from "../../api/cookie";

const router = useRouter()
const route = useRoute()
let form = reactive({
  ran_name: route.params.ran_anme
})
let {getCode, getCodeTitle} = yanZhengMa()

function onSubmit() {
  http.post("/gzhphp/login/wx_bang_ding_mobile", form).then(da => {
    if (da.code === 1) {
      setUserInfo(da.data)
      router.push(getReturnUrl())
    }
  })
}

</script>

<style scoped lang="less">
@import "../../assets/basis_css/style/var";

.bang-ding {
  text-align: center;
  margin-top: 30px;
  font-size: 22px;

  small {
    font-size: 16px;
    color: @gray-6;
    display: block;
    margin-top: 12px;
    margin-bottom: 40px;
  }

}
</style>
