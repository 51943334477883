import http from "../../api/http";
import {Toast} from 'vant'
import {ref, computed} from 'vue'

export const yanZhengMa = () => {
    let isAutoMobile = ref(false)//true = 自动填写手机号
    let daoJiShi = ref(60)
    let ollVal = ref(60) //倒计时几秒

    //获取手机号
    function getMobiel() {
        return new Promise((s) => {
            http.get('/gzhphp/fu_wu_shang/getUserMobile').then(da => {
                if (da.code === 1) {
                    s(da.data.mobile)
                    if (da.data.mobile) {
                        isAutoMobile.value = true
                    } else {
                        isAutoMobile.value = false
                    }

                }
            })
        })
    }

    //获取验证码
    function getCode(mobile) {

        if (daoJiShi.value !== ollVal.value) {
            return
        }
        http.get('/mobile/index/get_code', {params: {mobile: mobile}}).then(da => {
            if (da.code === 1) {
                Toast.success(da.msg)
                this.startJiShi()
            }
        })
    }

    function startJiShi() {
        daoJiShi.value--
        let jiSHi = setInterval(() => {
            daoJiShi.value--
            if (daoJiShi.value <= 1) {
                daoJiShi.value = ollVal.value
                clearInterval(jiSHi)
            }

        }, 1000)
    }

    let getCodeTitle = computed(() => {
        if (daoJiShi.value === ollVal.value) {
            return '获取验证码'
        }
        return `${daoJiShi.value}s后重试`
    })

    return {
        isAutoMobile,
        getCodeTitle, // 动态倒计时
        getCode, //发送验证码
        getMobiel
    }
}

